import axios from 'axios';

class ApiService {
    archivePmRecords(payload) {
        return axios.post('archive/pm/catching/records', payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }

    getDuplicateRecords(projectId) {
        return axios.get(`catch_release/catching/duplicate/records/${projectId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }

    mergeDuplicateRecords(projectId) {
        return axios.post(`catch_release/catching/merge/duplicate/records/${projectId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }
}

export default new ApiService();