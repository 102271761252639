<template>
    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12">
                <h5>Records <i style="color: darkgrey" class="ti-angle-right small"></i> Cleared For Sterilization</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>To search by date enter date in YYYY-MM-DD Format. Eg: 2024-01-31</p>
            </div>
            <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row set_row">
                            <!-- <div class="col-xl-1">
                            </div> -->
                            <div class="col-sm-12 col-xl-12 set_table_center">
                                <table id="notOperatedRecords" class="display table-striped table-bordered select"
                                       cellspacing="0" width="100%">
                                    <thead>
                                    <tr>
                                        <th><input type="checkbox" id="allCheckbox" @click="inputCheckBox"></th>
                                        <th>Caught Date</th>
                                        <th>Photo</th>
                                        <th>Collar ID</th>
                                        <th>Gender</th>
                                        <th>Caught Method</th>
                                        <th>Ownership</th>
                                        <th>RFID</th>
                                        <th>HSIApps ID</th>
                                        <th>Species</th>
                                        <th>Area</th>
                                        <th>Caught Location</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <!-- <div class="col-xl-1">
                            </div> -->
                        </div>
                    </div>
                    <div v-if="!isReadOnly" class="card-footer">
                        <button v-bind:disabled="!onLine" class="btn btn-warning mr-2" type="button"
                                @click="showModal=true">Mark for Release
                        </button>
                        <button v-bind:disabled="!onLine" class="btn btn-warning edit_btn mr-2" type="button"
                                @click="changeStageOfSelectedRecords">
                            Change Stage
                        </button>
<!--                        <button v-bind:disabled="!onLine" class="btn btn-warning edit_btn" type="button"  @click="editBulkRecords">-->
<!--                            Edit Selected Records-->
<!--                        </button>-->
                       <button v-bind:disabled="!onLine" class="btn btn-warning edit_btn mr-2" type="button"  @click="editBulkRecords">
                            Bulk Sterilization
                       </button>
                       <button type="button" class="btn" @click="archiveRecords">Archive</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal pm-mark-for-release-modal fade" role="dialog" v-if="showModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header edit-header">
                        <h4 class="modal-title">To be released date</h4>
                    </div>
                    <div class="modal-body modal-body-noteoprated">
                        <FullCalendar
                                height="100"
                                content-height="100"
                                aspect-ratio="1.8"
                                defaultView="dayGridMonth"
                                :plugins="calendarPlugins"
                                :selectable="true"
                                @select="handleSelect"
                        />
                    </div>
                    <hr/>
                    <div class="row set_footer">
                        <div class="col-md-8 selected-date">
                            <b>Selected Date :
                                {{ currentSelectDate != '' ? currentSelectDate : showDate.toLocaleDateString() }}</b>
                        </div>
                        <div class="col-md-4" style="text-align:right">
                            <button type="button" class="btn btn-default" style="background-color: #eb6532 !important;"
                                    @click="showModal=false">Close
                            </button>
                            <button type="button" class="btn btn-default" style="background-color: #2a9055 !important;"
                                    @click="markForRelease">SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="showChangeStageDialog">
            <PmRecordStageChangerDialog
                :record-ids="selectedRecordIds"></PmRecordStageChangerDialog>
        </template>

    </div>
</template>
<script>
import mixin from '../../../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import {Select, Option,} from 'element-ui';
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'
import PmRecordStageChangerDialog from "../../../PmRecordStageChangerDialog";
import apiService from '../../../../services/api.service';

export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        FullCalendar,
        VOffline,
        PmRecordStageChangerDialog,
    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data()
    {
        return {
            selectedRecordIds: [],
            showChangeStageDialog: false,

            isReadOnly:false,
            table: null,
            showModal: false,
            showDate: new Date(),
            currentSelectDate: '',
            calendarPlugins: [dayGridPlugin, interactionPlugin]
        }
    },
    mixins: [mixin],
    methods: {
        changeStageOfSelectedRecords() {

            const vm = this
            vm.selectedRecordIds = []

            document.querySelectorAll('.check_input').forEach(function (element) {
                var inputChecked = element.checked;
                if (inputChecked == true) {
                    vm.selectedRecordIds.push(element.value);
                }
            });

            if (vm.selectedRecordIds.length > 0) {
                vm.showChangeStageDialog = true
            } else {
                vm.showAlert('Alert', 'No records selected', false)
            }


        },
        addHealthRecordViewListener(editEl)
        {
            let vm = this;
            editEl[0].addEventListener('click', function () {
                let id = this.getAttribute("data-data");
                vm.$router.push({name: 'HealthRecordDetailsPm', params: {recordId: id}})
            }, false);
        },
        NotOperatedRecords()
        {
            let vm = this;

            if (vm.table != null) {
                vm.table.destroy();
            }
            try {
                vm.table = $('#notOperatedRecords').DataTable({
                    "columnDefs": [
                        {"visible": true, "targets": [12]}
                    ],
                    "order": [[1, "desc"]],
                    "scrollX": true,
                    responsive: true,
                    dom: 'lBfrtip',
                    "bInfo": false,
                    'columns': [
                        {
                            'targets': 0,
                            'render': function (data, type, full, meta) {
                                return '<input type="checkbox" class="check_input" name="id[]" value="' + full[11] + '">';
                            },
                            'orderable':  false
                        }, {
                            'targets': 1,
                            'render': function (data, type, full, meta) {
                                return vm.formatRecordDisplayDate(full[0], 'Do MMM YYYY, HH:mm:ss', ',');
                            }
                        },
                        {
                            'targets': 2,
                            'render': function (data, type, full, meta) {
                                return '<img src=' + full[1] + ' class="datatable_image_thumbnail" style="cursor: pointer; object-fit: cover;" height="70" width="70"/>';
                            }
                        },
                        {
                            'targets': 3,
                            'render': function (data, type, full, meta) {
                                return full[2];
                            }
                        }, {
                            'targets': 4,
                            'render': function (data, type, full, meta) {
                                return full[3];
                            }
                        }, {
                            'targets': 5,
                            'render': function (data, type, full, meta) {
                                return full[4];
                            }
                        }, {
                            'targets': 6,
                            'render': function (data, type, full, meta) {
                                return full[5];
                            }
                        }, {
                            'targets': 7,
                            'render': function (data, type, full, meta) {
                                return full[6];
                            }
                        }, {
                            'targets': 8,
                            'render': function (data, type, full, meta) {
                                return full[7];
                            }
                        }, {
                            'targets': 9,
                            'render': function (data, type, full, meta) {
                                return full[8];
                            }
                        }, {
                            'targets': 10,
                            'render': function (data, type, full, meta) {
                                return full[9];
                            }
                        }, {
                            'targets': 11,
                            'render': function (data, type, full, meta) {
                                return `<a href="https://www.google.com/maps/search/?api=1&query=${full[10]}" target="_blank">Map</a>`
                            }
                        }, {
                            'targets': 12,
                            'render': function (data, type, full, meta) {
                                if(vm.isReadOnly){
                                    return '<button type="button" class="btn btn-warning viewHealthRecordDetails" data-data="' + full[11] + '">View</button>';
                                }
                                return '<button type="button" class="btn btn-warning viewHealthRecordDetails" data-data="' + full[11] + '">View/Edit</button>';
                                //return '<span class="ti-eye viewOwnerRecord" style="padding: 10px;cursor: pointer;"></span>';
                                //return ''
                            }
                        }
                    ],
                    buttons: [
                        {
                            extend: 'excel',
                            text: 'Export to excel',
                            className: 'btn btn-default',
                            exportOptions: {
                                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                            }
                        },
                        {
                            extend: 'colvis',
                            text: 'Filter Columns',
                            className: 'btn btn-default'
                        }
                    ],
                    "processing": true,
                    "serverSide": true,
                    "ajax": {
                        "url": window.DATATABLES_BASE_URL + "records/clearedForSterilization",
                        "type": "POST",
                        "data": {
                            project_id: vm.projectId,
                        }
                    },
                    'fnRowCallback': function (nRow, aData, iDisplayIndex) {
                        const viewEditBtn = $('.viewHealthRecordDetails', nRow);
                        vm.addHealthRecordViewListener(viewEditBtn);
                        const imageElement = $('.datatable_image_thumbnail', nRow);
                        vm.zoomImagePopup(imageElement);
                        return nRow;
                    },
                    lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                    pageLength: 15,
                });
            } catch (e) {
                console.log(e)
            }
        },
        editViewRecord()
        {
            var self = this;
            var editIcon = document.querySelectorAll('.view_edit');
            for (var i = 0; i < editIcon.length; i++) {
                editIcon[i].addEventListener('click', function () {
                    window.location = '/project/mark_release/' + self.projectId;
                }, false);
            }
        },
        inputCheckBox()
        {
            if (document.getElementById('allCheckbox').checked) {
                console.log("if");
                document.querySelectorAll('.check_input').forEach(function (element) {
                    element.checked = true;
                });
            } else {
                console.log("else");
                document.querySelectorAll('.check_input').forEach(function (element) {
                    element.checked = false;
                });
            }
        },
        setShowDate(date)
        {
            this.showDate = date;
        },
        handleSelect(e)
        {
            let date = new Date(e.start);
            let fDate = (date.getDate()) + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear())
            this.currentSelectDate = fDate;
        },
        markForRelease()
        {
            let catch_release_record_ids = [];
            let selectDate;
            let vm = this;
            if (vm.currentSelectDate == '') {
                let date = new Date(vm.showDate);
                let fDate = (date.getDate()) + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear())
                selectDate = fDate;
            } else {
                selectDate = vm.currentSelectDate;
            }
            document.querySelectorAll('.check_input').forEach(function (element) {
                var inputChecked = element.checked;
                if (inputChecked == true) {
                    var checkInputValue = element.value;
                    catch_release_record_ids.push(checkInputValue);
                    axios.post('catch_release/mark_for_release', {
                        catch_release_record_ids: catch_release_record_ids,
                        release_date: selectDate,
                    }).then(response => {
                        if (response.data.response.error.error_code === 0) {
                            vm.showModal = false;
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            vm.NotOperatedRecords();
                        }
                    }).catch(error => {
                        vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                    })
                }
            })
            if (catch_release_record_ids.length == 0) {
                vm.showModal = false;
                vm.showAlert('Alert', 'Please select record(s)', false);
            }
        },
        editBulkRecords() {
            const selectedRecords = []
            document.querySelectorAll('.check_input').forEach(function (element) {
                var inputChecked = element.checked;
                if (inputChecked == true) {
                    selectedRecords.push(element.value);
                }
            });
            this.$router.push({name: 'EditBulkRecords', query: { ref: 'notoperated', selectedRecordIds: JSON.stringify(selectedRecords), fromText: 'Cleared For Sterilization' }})
        },
        async archiveRecords() {
            const vm = this;
            const selectedRecordIds = [];
            document.querySelectorAll('.check_input').forEach(function (element) {
                var inputChecked = element.checked;
                if (inputChecked == true) {
                    selectedRecordIds.push(element.value);
                }
            });

            if (selectedRecordIds.length > 0) {
                const loading = vm.$loading({
                    lock: true,
                    text: 'Archiving records...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                const apiResponse = await apiService.archivePmRecords({ids: selectedRecordIds});

                loading.close();

                if (apiResponse.response.error.error_code === 0) {
                    vm.showModal = false;
                    vm.showAlert('Success', apiResponse.response.error.msg, true);
                    vm.NotOperatedRecords();
                } else {
                    vm.showAlert('Error', apiResponse.response.error.error_msg, false);
                }
            } else {
                vm.showAlert('Alert', 'No records selected', false)
            }
        }
    },
    computed: {},
    mounted()
    {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;

        vm.projectId = vm.project.id;

        vm.isReadOnly = false
        vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
            if(r.role_name == 'VIEWER'){
                vm.isReadOnly = true
            }
        })

        window.Bus.$on('pm-close-stage-changer-dialog-box', function () {
            vm.showChangeStageDialog = false

            vm.NotOperatedRecords();
        })

        //Bus.$emit("projectype", vm.project);
        vm.NotOperatedRecords();
    }
}

</script>
<style lang="scss">
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.containBytitile h4 {
  font-size: 1.2em;
  line-height: 1.45em;
}

@media (max-width: 425px) {
  .edit_btn {
    margin-top: 10px !important;
  }
}
</style>
