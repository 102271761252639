<template>

    <div v-loading="isLoadingData" class="pm-sterlization">
        <el-form ref="sterlizationForm" :model="sterlizationModal" label-width="80px" label-position="top">
            <div class="row">
                <div class="col-lg-4">
                    <el-form-item
                        label="Select Date Time"
                        prop="dateTime"
                        :rules="[
                        { required: true, message: 'Please select date time', trigger: 'change' }
                    ]">
                        <el-date-picker
                            v-model="sterlizationModal.dateTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="Select date and time">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item
                        label="Select Veterinarian"
                        prop="vet"
                        :rules="[
                        { required: true, message: 'Please select veterinarian', trigger: 'change' }
                    ]">
                        <el-select v-model="sterlizationModal.vet" placeholder="Select">
                            <el-option
                                v-for="item in list.vets"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Select Conditions (found during Surgery)" prop="conditions">
                        <el-select v-model="sterlizationModal.conditions" multiple placeholder="Multi Select">
                            <el-option
                                v-for="item in list.conditions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="row">
                        <div class="col-lg-6">
                            <el-form-item label="Number of Top-ups" prop="noOfTopUps">
                                <el-select v-model="sterlizationModal.noOfTopUps" placeholder="Select">
                                    <el-option
                                        v-for="item in list.topUps"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-lg-6">
                            <el-form-item
                                label="Anaesthesia Given"
                                prop="anaesthesiaGiven"
                                :rules="[
                                { validator: checkAnaesthesiaGiven}
                            ]">
                                <el-input type="number" placeholder="" v-model="sterlizationModal.anaesthesiaGiven">
                                    <template slot="append">ml</template>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item
                        label="Surgery Duration"
                        prop="surgeryDuration"
                        :rules="[
                        { required: true, message: 'Surgery Duration is required' },
                        { validator: checkSurgeryDuration}
                    ]">
                        <el-input type="number" v-model="sterlizationModal.surgeryDuration">
                            <template slot="append">mins</template>
                        </el-input>
                    </el-form-item>
                </div>

                <div class="col-lg-4">
                    <template v-if="isFemaleRecord">
                        <el-checkbox v-model="sterlizationModal.pregnant" @change="resetPregnantFields">Pregnant
                        </el-checkbox>

                        <el-form-item
                            label="Fetus Count"
                            prop="fetusCount"
                            :rules="[
                        { required: sterlizationModal.pregnant, message: 'Fetus Count is required' },
                    ]">
                            <el-select
                                v-bind:disabled="!sterlizationModal.pregnant"
                                v-model="sterlizationModal.fetusCount"
                                placeholder="Fetus Count">
                                <el-option
                                    v-for="item in list.topUps"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="Pregnancy Stage"
                            prop="pregnancyStage"
                            :rules="[
                        { required: sterlizationModal.pregnant, message: 'Pregnancy Stage is required' },
                    ]">
                            <el-select v-bind:disabled="!sterlizationModal.pregnant"
                                       v-model="sterlizationModal.pregnancyStage"
                                       placeholder="Select">
                                <el-option
                                    v-for="item in list.pregnancyStages"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </template>

                    <el-form-item prop="resource">
                    <span slot="label">
                        Rabies Vaccination?
                        <el-tooltip content="Reset" placement="top">
                            <i class="ti-reload reset-rabies-vaccination" @click="resetRabiesVaccination"></i>
                        </el-tooltip>
                    </span>
                        <el-radio-group class="rabies-vaccination-given"
                                        v-model="sterlizationModal.rabiesVaccinationGiven">
                            <el-radio label="Vaccination Given"
                                      value="Vaccination Given"></el-radio>
                            <el-radio label="Already Vaccinated"
                                      value="Already Vaccinated"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>

                <div class="col-lg-4">
                    <el-form-item label="Sterilization Result"
                                  prop="sterlizationResult"
                                  :rules="[
                        { required: true, message: 'Please select Sterilization Result', trigger: 'change' }
                    ]">
                        <el-select v-model="sterlizationModal.sterlizationResult"
                                   @change="sterlizationModal.postOpHold=sterlizationModal.sterlizationResult=='Sterilization Aborted'"
                                   placeholder="Select">
                            <el-option
                                v-for="item in list.sterlizationResults"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div v-if="sterlizationModal.sterlizationResult === 'Already Sterilized'">
                            <small>Upon opening abdominal cavity, animal found to have already been sterilized.</small>
                        </div>
                    </el-form-item>
                    <el-form-item label="Complications" prop="complication">
                        <el-select v-model="sterlizationModal.complication" multiple placeholder="Multi Select">
                            <el-option
                                v-for="item in list.complications"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <template v-if="sterlizationModal.complication.includes('Other')">
                        <el-form-item label="Complication Note" prop="otherComplicationNote">
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="Please input"
                                v-model="sterlizationModal.otherComplicationNote">
                            </el-input>
                        </el-form-item>
                    </template>
                    <el-form-item label="Release Date" prop="releaseDate">
                        <el-date-picker
                            v-bind:disabled="sterlizationModal.postOpHold"
                            v-model="sterlizationModal.releaseDate"
                            type="date"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="Select date">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Note" prop="note">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="Please input"
                            v-model="sterlizationModal.note">
                        </el-input>
                    </el-form-item>
                    <el-checkbox v-model="sterlizationModal.postOpHold" @change="validateReleaseDate">Post-Op Hold
                    </el-checkbox>
                </div>
            </div>


            <div class="row text-right">
                <div class="col-sm-12">
                    <button type="button" class="btn btn-success" :disabled="isSavingData||isLoadingData"
                            @click="saveOrUpdateSterilisation">{{ getButtonLabel }}
                    </button>
                </div>
            </div>
        </el-form>
    </div>

</template>

<script>

import {Select, Option, DatePicker, Input, Checkbox, Radio, Tooltip, Form, FormItem, RadioGroup} from 'element-ui';

export default {

    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        [DatePicker.name]: DatePicker,
        [Input.name]: Input,
        [Checkbox.name]: Checkbox,
        [Radio.name]: Radio,
        [Tooltip.name]: Tooltip,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [RadioGroup.name]: RadioGroup
    },
    data() {
        return {

            isFemaleRecord: false,


            isLoadingData: false,
            isSavingData: false,

            sterlizationModal: {
                dateTime: '',
                vet: '',
                conditions: [],
                noOfTopUps: '0',
                anaesthesiaGiven: '',
                surgeryDuration: '',
                pregnant: false,
                fetusCount: '',
                pregnancyStage: '',
                rabiesVaccinationGiven: false,
                sterlizationResult: '',
                complication: [],
                otherComplicationNote: '',
                releaseDate: '',
                note: '',
                postOpHold: false,
            },
            list: {
                vets: [],
                conditions: [
                    {
                        value: 'Pyometra',
                        label: 'Pyometra'
                    },
                    {
                        value: 'Hydrometra',
                        label: 'Hydrometra'
                    },
                    {
                        value: 'Ascites',
                        label: 'Ascites'
                    },
                    {
                        value: 'Cystic Ovaries',
                        label: 'Cystic Ovaries'
                    },
                    {
                        value: 'Hernia',
                        label: 'Hernia'
                    },
                    {
                        value: 'Endometriosis',
                        label: 'Endometriosis'
                    },
                    {
                        value: 'Cryptorchid',
                        label: 'Cryptorchid'
                    },
                    {
                        value: 'Monorchid',
                        label: 'Monorchid'
                    }
                ],
                topUps: [
                    {label: '0', value: '0'}, {label: '1', value: '1'}, {label: '2', value: '2'}, {
                        label: '3',
                        value: '3'
                    }, {
                        label: '4',
                        value: '4'
                    }, {label: '5', value: '5'}, {label: '6', value: '6'}, {label: '7', value: '7'}, {
                        label: '8',
                        value: '8'
                    }, {label: '9', value: '9'}, {label: '10', value: '10'}, {label: '11', value: '11'}, {
                        label: '12',
                        value: '12'
                    }, {label: '13', value: '13'}, {label: '14', value: '14'}, {label: '15', value: '15'},
                ],
                pregnancyStages: [
                    {
                        label: 'Early',
                        value: 'Early'
                    },
                    {
                        label: 'Mid',
                        value: 'Mid'
                    },
                    {
                        label: 'Advance',
                        value: 'Advance'
                    },
                ],
                sterlizationResults: [
                    {
                        label: 'Sterilization Successful',
                        value: 'Sterilization Successful'
                    },
                    {
                        label: 'Already Sterilized',
                        value: 'Already Sterilized'
                    },
                    {
                        label: 'Sterilization Aborted',
                        value: 'Sterilization Aborted'
                    },
                ],
                complications: [
                    {
                        label: 'Bleeding',
                        value: 'Bleeding'
                    },
                    {
                        label: 'Increased Incision',
                        value: 'Increased Incision'
                    },
                    {
                        label: 'Other',
                        value: 'Other'
                    },
                ]
            }
        }
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        recordId: {
            type: String,
            required: true
        }
    },
    methods: {
        init() {
            if (this.id != null) {
                this.getOperationDetails();
            }
            this.getVets();
        },
        saveOrUpdateSterilisation() {
            this.$refs['sterlizationForm'].validate((valid) => {
                if (valid) {
                    let vm = this;

                    if (vm.sterlizationModal.sterlizationResult === 'Sterilization Successful') {
                        if (!vm.sterlizationModal.postOpHold && vm.sterlizationModal.releaseDate === '') {
                            vm.showAlert('Error', "You must select a release date or post-op hold if sterilization is set to successful", false);
                            return
                        }
                    }

                    vm.isSavingData = true

                    let url = "";

                    if (vm.id != null) {
                        if (vm.id > 0) {
                            url = '/operation/update/sterilisation/' + vm.recordId + '/' + vm.id;
                        } else {
                            url = '/operation/add/sterilisation/' + vm.recordId;
                        }
                    } else {
                        url = '/operation/add/sterilisation/' + vm.recordId;
                    }

                    axios.post(url, {
                        operation_date: vm.sterlizationModal.dateTime,
                        surgeon_name: vm.sterlizationModal.vet,
                        operation_result: vm.sterlizationModal.sterlizationResult,
                        anesthesia_given: vm.sterlizationModal.anaesthesiaGiven,
                        duration: vm.sterlizationModal.surgeryDuration,
                        note: vm.sterlizationModal.note,
                        rabies_vaccine: vm.sterlizationModal.rabiesVaccinationGiven,
                        top_ups: vm.sterlizationModal.noOfTopUps,
                        complications: vm.sterlizationModal.complication,
                        fetus_count: vm.sterlizationModal.fetusCount,
                        pregnancy_stage: vm.sterlizationModal.pregnancyStage,
                        conditions: vm.sterlizationModal.conditions,
                        release_date: vm.sterlizationModal.releaseDate,
                        complication_other_note: vm.sterlizationModal.otherComplicationNote,
                        post_op_hold: vm.sterlizationModal.postOpHold ? 1 : 0
                    })
                        .then(response => {
                            vm.isSavingData = false
                            if (response.data.response.error.error_code == 0) {

                                this.$root.$emit('HistoryTableUpdateRecords') //like this
                                if (vm.id > 0) {
                                    vm.$message({
                                        message: 'Sterilization Details Updated Successfully.',
                                        type: 'success'
                                    });
                                } else {
                                    vm.clearModalData()
                                    vm.showAlert('Success', response.data.response.error.msg, true);
                                }
                            }
                        })
                        .catch(e => {
                            vm.isSavingData = false
                            console.log(e);
                        })
                } else {
                    return false;
                }
            });

        },
        clearModalData() {
            this.sterlizationModal.dateTime = ''
            this.sterlizationModal.vet = ''
            this.sterlizationModal.conditions = []
            this.sterlizationModal.noOfTopUps = '0'
            this.sterlizationModal.anaesthesiaGiven = ''
            this.sterlizationModal.surgeryDuration = ''
            this.sterlizationModal.pregnant = false
            this.sterlizationModal.fetusCount = ''
            this.sterlizationModal.pregnancyStage = ''
            this.sterlizationModal.rabiesVaccinationGiven = false
            this.sterlizationModal.sterlizationResult = ''
            this.sterlizationModal.complication = []
            this.sterlizationModal.otherComplicationNote = ''
            this.sterlizationModal.releaseDate = ''
            this.sterlizationModal.note = ''
            this.sterlizationModal.postOpHold = false
            this.$refs['sterlizationForm'].resetFields();
        },
        resetPregnantFields(checked) {
            if (!checked) {
                this.sterlizationModal.fetusCount = '';
                this.sterlizationModal.pregnancyStage = '';
                this.$refs['sterlizationForm'].clearValidate(['fetusCount', 'pregnancyStage'])
            }
        },
        getOperationDetails() {
            let vm = this;

            vm.isLoadingData = true

            axios.get('/operation/sterilisation/' + vm.id)
                .then(response => {
                    vm.isLoadingData = false
                    if (response.data.response.error.error_code == 0) {
                        let record = response.data.response.data.operation;
                        vm.sterlizationModal.dateTime = record.operation_date
                        if (record.fetus_count != '') {
                            vm.sterlizationModal.pregnant = true
                            vm.sterlizationModal.fetusCount = record.fetus_count
                            vm.sterlizationModal.pregnancyStage = record.pregnancy_stage
                        }
                        vm.sterlizationModal.vet = record.surgeon_name
                        vm.sterlizationModal.noOfTopUps = record.top_ups
                        vm.sterlizationModal.conditions = JSON.parse(record.conditions)
                        vm.sterlizationModal.anaesthesiaGiven = record.anesthesia_given
                        vm.sterlizationModal.surgeryDuration = record.duration
                        vm.sterlizationModal.rabiesVaccinationGiven = record.rabies_vaccine
                        vm.sterlizationModal.sterlizationResult = record.operation_result
                        vm.sterlizationModal.complication = JSON.parse(record.complications)
                        vm.sterlizationModal.otherComplicationNote = record.complication_other_note
                        vm.sterlizationModal.note = record.note
                        vm.sterlizationModal.postOpHold = record.post_op_hold == 1
                        vm.sterlizationModal.releaseDate = record.catch_release_record.release_date
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        },
        getVets() {
            var vm = this;
            axios.get("/organisation/get/vets", {
                params: {
                    organisation_id: vm.$store.getters.getCurrentSelectedProject.organisation_id,
                },
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.list.vets = []
                    var v = response.data.response.data.vets;
                    for (let i = 0; i < v.length; i++) {
                        vm.list.vets.push({
                            value: v[i].first_name + ' ' + v[i].last_name,
                            label: v[i].first_name + ' ' + v[i].last_name
                        });
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        },
        resetRabiesVaccination() {
            this.sterlizationModal.rabiesVaccinationGiven = false
        },
        checkSurgeryDuration(rule, value, callback) {
            if (value < 0 || value > 999) {
                callback(new Error('Surgery duration must be between 0 to 999'));
            } else {
                callback();
            }
        },
        checkAnaesthesiaGiven(rule, value, callback) {
            if (value < 0 || value > 999) {
                callback(new Error('Anaesthesia ml must be between 0 to 999'));
            } else {
                callback();
            }
        },
        validateReleaseDate() {
            this.sterlizationModal.releaseDate = '';
        }
    },
    computed: {
        getButtonLabel: function () {
            if (this.id != null) {
                if (this.id > 0) {
                    return "UPDATE"
                } else {
                    return "SAVE"
                }
            } else {
                return "SAVE"
            }

        }
    },
    mounted() {
        this.init();
    }

}

</script>

<style scoped lang="scss">
.reset-rabies-vaccination {
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
}

.rabies-vaccination-given {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    .el-radio {
        display: flex;
        margin-left: 0;;
        padding-bottom: 10px;
    }
}
</style>
