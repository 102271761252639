<template>
    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12">
                <!-- <h5>Archived Records</h5> -->
                <h5 class="set_font_title">Archived Records</h5>
                <hr>
            </div>
        </div>

        <tabs value="Archived Records">
            <tab-pane title="Vaccinations" key="vaccination">
                <div class="row">
                    <div class="col-md-12 card">
                        <div class="card-header">
                            <div class="category">Vaccination Records</div>
                        </div>
                        <div class="card-body row">
                            <div class="col-sm-12 col-xl-12">
                                <table id="vaccRecordsArchived" class="display table-striped table-bordered select"
                                       cellspacing="0" width="100%">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Date Time</th>
                                        <th>Image</th>
                                        <th>HSIApps ID</th>
                                        <th>Species</th>
                                        <th>Gender</th>
                                        <th>Reproductive Status</th>
                                        <th>Ownership</th>
                                        <th>Area</th>
                                        <th>Vaccinator</th>
                                        <th>Archived By</th>
                                    </tr>
                                    </thead>
                                </table>
                                <div class="float-right">
                                    <button v-if="bulkUnArchiveData.isLoading" class="btn btn-primary" type="button"
                                            disabled>
                                        <span class="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                    <button v-else class="btn btn-default"
                                            :disabled="!bulkUnArchiveData.selectedEntries.length"
                                            @click="bulkUnArchive">Unarchive Selected
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="myModal" class="modal_full_img">
                    <span class="close colse-one">&times;</span>
                    <img class="modal-content-full-img" id="img01">
                    <div id="caption"></div>
                </div>
            </tab-pane>


            <tab-pane title="Out of Area" key="outer">
                <div class="row">
                    <div class="col-md-12 card">
                        <div class="card-header">
                            <div class="category">Out of Area Vaccination Records</div>
                        </div>
                        <div class="card-body row">
                            <div class="col-sm-12 col-xl-12">
                                <table id="OuterVaccinationsArchived"
                                       class="display table-striped table-bordered select" cellspacing="0" width="100%">
                                    <thead>
                                    <tr>
<!--                                        <th>ID</th>-->
                                        <th>Ownership</th>
                                        <th>Age Category</th>
                                        <th>Reproductive Status</th>
<!--                                        <th>Drug Name</th>-->
                                        <th>Vaccinated Date</th>
                                        <th>Vaccinated By</th>
                                        <th>Note</th>
                                        <th>Species</th>
                                        <th>Gender</th>
                                        <th>Area</th>
                                        <th>Unarchive</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </tab-pane>


            <tab-pane title="Surveys" key="survey" v-on:click="getArchivedSurveys()">
                <div class="row">
                    <div class="col-md-12 card">
                        <div class="card-header">
                            <div class="category">Survey Records</div>
                        </div>
                        <div class="card-body row">
                            <div class="col-sm-12 col-xl-12">
                                <table id="SurveyArchived" class="display table-striped table-bordered select"
                                       cellspacing="0" width="100%">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Vaccinated</th>
                                        <th>Location</th>
                                        <th>Created At</th>
                                        <th>Surveyor</th>
                                        <th>Area</th>
                                        <th>Archived by</th>
                                    </tr>
                                    </thead>
                                </table>
                                <div class="float-right">
                                    <button v-if="surveyBulkUnArchiveData.isLoading" class="btn btn-primary"
                                            type="button" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                    <button v-else class="btn btn-default"
                                            :disabled="!surveyBulkUnArchiveData.selectedEntries.length"
                                            @click="surveyBulkUnArchive">Unarchive Selected
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab-pane>
        </tabs>


    </div>
</template>
<script>


import mixin from '../../mixins/GlobalMixin.js';


import Vue from 'vue';
import {Table, TableColumn, Select, Option} from 'element-ui';
import PPagination from './UIComponents/Pagination.vue';

import users from '../Template/Tables/users';

import Swal from 'sweetalert2';

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);

import {Tabs, TabPane} from './UIComponents';
import VOffline from 'v-offline';

export default {

    components: {
        PPagination,
        TabPane,
        Tabs,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        },
    },
    mixins: [mixin],
    data()
    {
        return {
            archivedVaccinations: [],
            archivedOuterVaccinations: [],
            archivedSurveys: [],

            vaccinationPagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            vaccinationSearchQuery: '',
            vaccinationPropsToSearch: ['area_name', 'animal_name', 'drug'],
            vaccinationTableData: [],

            outerVaccinationPagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            outerVaccinationSearchQuery: '',
            outerVaccinationPropsToSearch: ['area_name', 'animal_name', 'drug'],
            outerVaccinationTableData: [],

            surveyPagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            surveySearchQuery: '',
            surveyPropsToSearch: ['record_taken_by', 'area', 'google_locality'],
            surveyTableData: [],
            vaccTable: null,
            surveyTable: null,
            outerTable: null,
            bulkUnArchiveData: {
                selectedEntries: [],
                isLoading: false,
            },
            surveyBulkUnArchiveData: {
                selectedEntries: [],
                isLoading: false,
            }
        }
    },
    methods: {
        init()
        {
            this.getArchivedVaccinations();
        },


        getArchivedVaccinations()
        {
            let vm = this;
            if (vm.vaccTable != null) {
                vm.vaccTable.destroy();
            }
            try {
                vm.vaccTable = $('#vaccRecordsArchived').DataTable({
                    "scrollX": true,
                    responsive: true,
                    dom: 'lBfrtip',
                    "bInfo": false,
                    'columns': [
                        {
                            'targets': 0,
                            'render': function (data, type, full, meta) {
                                return `<input type="checkbox" id="rowCheck${data}" />`
                            },
                            'orderable': false
                        },
                        {
                            'targets': 1,
                            'render': function (data, type, full, meta) {
                                return vm.formatRecordDisplayDate(full[1], 'DD MMM YYYY, HH:mm:ss', ',');
                            }
                        }, {
                            'targets': 2,
                            'render': function (data, type, full, meta) {
                                return '<img src=' + full[2] + ' class="datatable_image_thumbnail" style="cursor: pointer; object-fit: cover;" height="70" width="70"/>';
                            }
                        }, {
                            'targets': 3,
                            'render': function (data, type, full, meta) {
                                return full[3];
                            }
                        }, {
                            'targets': 4,
                            'render': function (data, type, full, meta) {
                                return full[4];
                            }
                        }, {
                            'targets': 5,
                            'render': function (data, type, full, meta) {
                                return full[5];
                            }
                        }, {
                            'targets': 6,
                            'render': function (data, type, full, meta) {
                                return full[6];
                            }
                        }, {
                            'targets': 7,
                            'render': function (data, type, full, meta) {
                                return full[7];
                            }
                        }, {
                            'targets': 8,
                            'render': function (data, type, full, meta) {
                                return full[8];
                            }
                        }, {
                            'targets': 9,
                            'render': function (data, type, full, meta) {
                                return full[9];
                            }
                        }, {
                            'targets': 10,
                            'render': function (data, type, full, meta) {
                                return full[10];
                            }
                        },
                        // {
                        //     'targets': 11,
                        //     'render': function (data, type, full, meta) {
                        //         var Ownership = full[7];
                        //         if (Ownership == "OWNED") {
                        //             return '<span class="ti-pencil editRecord" style="padding: 10px;cursor: pointer;"></span><span class="ti-eye viewOwnerRecord" style="padding: 10px;cursor: pointer;"></span>';
                        //         } else {
                        //             return '<span class="ti-pencil editRecord" style="padding: 10px;cursor: pointer;"></span>';
                        //         }
                        //     }
                        // }
                    ],
                    buttons: [
                        {
                            extend: 'csv',
                            text: 'csv',
                            className: 'btn btn-default',
                            exportOptions: {
                                format: {
                                    body: function (data, col, row) {
                                        var isImg = ~data.toLowerCase().indexOf('img') ? true : false;
                                        if (isImg) {
                                            var div = document.createElement('div');
                                            div.innerHTML = data;
                                            var firstImage = div.getElementsByTagName('img')[0]
                                            var rawImgSrc = firstImage ? firstImage.getAttribute("src") : "";
                                            return rawImgSrc;
                                        }
                                        var isSpan = ~data.toLowerCase().indexOf('span') ? true : false;
                                        if (isSpan) {
                                            return "";
                                        }
                                        return data;
                                    }
                                }
                            }
                        }
                    ],
                    'fnRowCallback': function (nRow, aData, iDisplayIndex) {
                        const checkBox = $("input[type='checkbox']", nRow);
                        vm.selectTableEntries(checkBox, nRow);
                        const imageElement = $('.datatable_image_thumbnail', nRow);
                        vm.zoomImagePopup(imageElement);
                        // var lastTd = $('td:eq(11)', nRow)[0];
                        // lastTd.firstChild.dataset.data = aData;
                        // lastTd.lastChild.dataset.data = aData;
                        if ($.inArray(aData.DT_RowId, vm.bulkUnArchiveData.selectedEntries) !== -1) {
                            $(nRow).addClass('selected');
                            checkBox.prop('checked', true);
                        }
                        return nRow;
                    },
                    "processing": true,
                    "serverSide": true,
                    "ajax": {
                        "url": window.DATATABLES_BASE_URL + "vaccinationRecordsArchived",
                        //"url": "http://localhost:8888/api/datatables/vaccinationRecordsArchived",
                        "type": "POST",
                        "data": {
                            project_id: vm.projectId,
                        },
                    },
                    lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                    pageLength: 15,
                });
            } catch (e) {
            }
        },
        getArchivedOuterVaccinations()
        {
            let vm = this;

            if (vm.outerTable != null) {
                vm.outerTable.destroy();
            }

            axios.get('/vaccination/archived/outer/' + vm.projectId).then(response => {
                if (response.data.response.error.error_code == 0) {
                    vm.archivedOuterVaccinations = [];
                    for (let i = 0; i < response.data.response.data.archived_records_outer.length; i++) {
                        let record = response.data.response.data.archived_records_outer[i];

                        let data = {
                            id: record.id,
                            ownership: record.ownership,
                            age_category: record.age_category,
                            reproductive_status: record.reproductive_status,
                            vaccinated_date: record.vaccination_date,
                            vaccinated_by: record.vaccinated_by,
                            note: record.note,
                            animal_name: record.animal_record.species,
                            gender: record.animal_record.gender,
                            area_name: record.area.name,
                        };
                        vm.archivedOuterVaccinations.push(data);
                    }
                    try {
                        vm.outerTable = $('#OuterVaccinationsArchived').DataTable({
                            "scrollX": true,
                            responsive: true,
                            data: vm.archivedOuterVaccinations,
                            dom: 'lBfrtip',
                            "bInfo": false,
                            columns: [
                                //{data: 'id'},
                                {data: 'ownership'},
                                {data: 'age_category'},
                                {data: 'reproductive_status'},
                                //{data: 'drug'},
                                {
                                    data: 'vaccinated_date',
                                    render: function( data, type, full, meta ) {
                                        return vm.formatRecordDisplayDate(data, 'DD MMM YYYY, HH:mm:ss', ',');
                                    }

                                },
                                {data: 'vaccinated_by'},
                                {data: 'note'},
                                {data: 'animal_name'},
                                {data: 'gender'},
                                {data: 'area_name'},
                                {data: ''},
                            ],
                            "columnDefs": [
                                {
                                    "targets": 9,
                                    "data": null,
                                    "defaultContent": '<button type="button" class="btn btn-icon btn-success btn-sm outerVaccinationsArchivedbtn"> <i class="fa fa-reply" style="margin: 0;"></i></button>'
                                }
                            ],
                            'fnRowCallback': function (nRow, aData, iDisplayIndex) {
                                var lastTd = $('td:eq(9)', nRow)[0];
                                console.log(lastTd);
                                console.log(aData);
                                lastTd.lastChild.dataset.data = aData.id;
                                return nRow;
                            },
                            buttons: [
                                {
                                    extend: 'csv',
                                    text: 'csv',
                                    className: 'btn btn-default',
                                    exportOptions: {
                                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                                    }
                                }
                            ],
                            lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                            pageLength: 15,
                        });

                        var outerVaccinationsArchived = document.querySelectorAll('.outerVaccinationsArchivedbtn');
                        for (var i = 0; i < outerVaccinationsArchived.length; i++) {
                            outerVaccinationsArchived[i].addEventListener('click', function () {
                                let id = this.getAttribute("data-data");
                                vm.restoreOuterVaccination(id);
                            }, false);
                        }

                    } catch (e) {
                        console.log(e);
                    }
                    //vm.outerVaccinationTableData = vm.archivedOuterVaccinations;
                }
            }).catch(error => {
                console.log(error);
            })
        },
        getArchivedSurveys()
        {
            let vm = this;
            if (vm.surveyTable != null) {
                vm.surveyTable.destroy();
            }
            try {
                vm.surveyTable = $('#SurveyArchived').DataTable({
                    "scrollX": true,
                    responsive: true,
                    dom: 'lBfrtip',
                    "bInfo": false,
                    'columns': [
                        {
                            'targets': 0,
                            'render': function (data, type, full, meta) {
                                return `<input type="checkbox" id="rowCheck${data}" />`
                            },
                            'orderable': false
                        }, {
                            'targets': 1,
                            'render': function (data, type, full, meta) {
                                return full[1];
                            }
                        }, {
                            'targets': 2,
                            'render': function (data, type, full, meta) {
                                return `<a href="https://www.google.com/maps/search/?api=1&query=${full[2]}" target="_blank">Open in maps </a>`
                            }
                        }, {
                            'targets': 3,
                            'render': function (data, type, full, meta) {
                                return full[3];
                            }
                        }, {
                            'targets': 4,
                            'render': function (data, type, full, meta) {
                                return full[4];
                            }
                        }, {
                            'targets': 5,
                            'render': function (data, type, full, meta) {
                                return full[5];
                            }
                        }, {
                            'targets': 6,
                            'render': function (data, type, full, meta) {
                                return full[6];
                            }
                        }
                    ],
                    buttons: [
                        {
                            extend: 'csv',
                            text: 'csv',
                            className: 'btn btn-default',
                            exportOptions: {
                                format: {
                                    body: function (data, col, row) {
                                        var isImg = ~data.toLowerCase().indexOf('img') ? true : false;
                                        if (isImg) {
                                            var div = document.createElement('div');
                                            div.innerHTML = data;
                                            var firstImage = div.getElementsByTagName('img')[0]
                                            var rawImgSrc = firstImage ? firstImage.getAttribute("src") : "";
                                            return rawImgSrc;
                                        }
                                        var isSpan = ~data.toLowerCase().indexOf('span') ? true : false;
                                        if (isSpan) {
                                            return "";
                                        }
                                        return data;
                                    }
                                }
                            }
                        },
                    ],
                    'fnRowCallback': function (nRow, aData, iDisplayIndex) {
                        const checkBox = $("input[type='checkbox']", nRow);
                        vm.surveySelectTableEntries(checkBox, nRow);

                    },
                    "processing": true,
                    "serverSide": true,
                    "ajax": {
                        "url": window.DATATABLES_BASE_URL + "surveyRecordsArchived",
                        //"url": "http://localhost:8888/api/datatables/surveyRecordsArchived",
                        "type": "POST",
                        "data": {
                            project_id: vm.projectId,
                        },
                    },
                    lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                    pageLength: 15,
                });
            } catch (e) {
            }
        },


        restoreVaccination(id)
        {
            //alert(`Your want to like ${row.id}`);
            //let id = row.id;
            let vm = this;
            new Swal({
                title: 'Alert!',
                text: 'Are you sure you want to restore this archived record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Restore',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {

                    axios.post('vaccination/restore/archived/' + id)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.getArchivedVaccinations();
                                vm.showAlert('Success', response.data.response.error.msg, true);
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })

                }
            });
        },
        restoreOuterVaccination(id)
        {
            //let id = row.id;
            let vm = this;
            new Swal({
                title: 'Alert!',
                text: 'Are you sure you want to restore this archived record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Restore',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {

                    axios.post('vaccination/restore/archived/' + id)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.getArchivedOuterVaccinations();
                                vm.showAlert('Success', response.data.response.error.msg, true);
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })

                }
            });
        },
        restoreSurvey(id)
        {
            //let id = row.id;
            let vm = this;
            new Swal({
                title: 'Alert!',
                text: 'Are you sure you want to restore this archived record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Restore',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {

                    axios.post('survey/restore/archived/' + id)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.getArchivedSurveys();
                                vm.showAlert('Success', response.data.response.error.msg, true);
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })

                }
            });
        },
        selectTableEntries(checkBoxEl, row)
        {
            const vm = this;
            checkBoxEl.on('change', function () {
                const id = row.id;
                const index = $.inArray(id, vm.bulkUnArchiveData.selectedEntries);
                if (index === -1) {
                    vm.bulkUnArchiveData.selectedEntries.push(id);
                } else {
                    vm.bulkUnArchiveData.selectedEntries.splice(index, 1);
                }
                $(row).toggleClass('selected');
            });
        },
        async bulkUnArchive()
        {
            if (this.bulkUnArchiveData.selectedEntries.length) {
                let selectedIds = this.bulkUnArchiveData.selectedEntries.map((el) => {
                    return el.replace('row_', '');
                })
                const requestPayload = {
                    record_ids: selectedIds,
                    // record_ids: ["241147", "241148"],
                    // area_id: +this.area_id,
                    project_id: +this.projectId
                }
                try {
                    this.bulkUnArchiveData.isLoading = true;
                    const bulkArchiveResponse = await axios.post('vaccination/bulk/unarchive', requestPayload);
                    this.getArchivedVaccinations();
                    this.showAlert('Success', bulkArchiveResponse.data.response.error.msg, true);
                    this.bulkUnArchiveData.selectedEntries = [];
                } catch (error) {
                    this.showAlert('Sorry', error.response.data.response.error.msg, false);
                }
                this.bulkUnArchiveData.isLoading = false;
            } else {
                this.showAlert('OOPS', 'Something went wrong !', false);
            }
        },
        surveySelectTableEntries(checkBoxEl, row)
        {
            const vm = this;
            checkBoxEl.on('change', function () {
                const id = row.id;
                const index = $.inArray(id, vm.surveyBulkUnArchiveData.selectedEntries);
                if (index === -1) {
                    vm.surveyBulkUnArchiveData.selectedEntries.push(id);
                } else {
                    vm.surveyBulkUnArchiveData.selectedEntries.splice(index, 1);
                }
                $(row).toggleClass('selected');
            });
        },
        async surveyBulkUnArchive()
        {
            if (this.surveyBulkUnArchiveData.selectedEntries.length) {
                let selectedIds = this.surveyBulkUnArchiveData.selectedEntries.map((el) => {
                    return el.replace('row_', '');
                })
                const requestPayload = {
                    record_ids: selectedIds,
                    // record_ids: ["241147", "241148"],
                    // area_id: +this.area_id,
                    project_id: +this.projectId,
                }
                try {
                    this.surveyBulkUnArchiveData.isLoading = true;
                    const surveyBulkUnArchiveResponse = await axios.post('survey/bulk/unarchive', requestPayload);
                    this.getArchivedSurveys()
                    this.showAlert('Success', surveyBulkUnArchiveResponse.data.response.error.msg, true);
                    this.surveyBulkUnArchiveData.selectedEntries = [];
                } catch (error) {
                    this.showAlert('Sorry', error.response.data.response.error.msg, false);
                }
                this.surveyBulkUnArchiveData.isLoading = false;
            } else {
                this.showAlert('OOPS', 'Something went wrong !', false);
            }
        }
    },
    created()
    {
        var self = this;
        setTimeout(function () {
            var vaccinationsArchived = document.querySelectorAll('.vaccinationsArchivedbtn');
            for (var i = 0; i < vaccinationsArchived.length; i++) {
                vaccinationsArchived[i].addEventListener('click', function () {
                    let id = this.getAttribute("data-data");
                    self.restoreVaccination(id);
                }, false);
            }

            var surveyArchived = document.querySelectorAll('.surveyArchivedbtn');
            for (var i = 0; i < surveyArchived.length; i++) {
                surveyArchived[i].addEventListener('click', function () {
                    let id = this.getAttribute("data-data");
                    self.restoreSurvey(id);
                }, false);
            }
        }, 2000);
    },
    computed: {
        vaccinatedPagedData()
        {
            return this.vaccinationTableData.slice(this.fromVaccination, this.toVaccination);
        },
        queriedVaccinationData()
        {
            if (!this.vaccinationSearchQuery) {
                this.vaccinationPagination.total = this.vaccinationTableData.length;
                return this.vaccinatedPagedData;
            }
            let result = this.vaccinationTableData.filter((row) => {
                let isIncluded = false;
                for (let key of this.vaccinationPropsToSearch) {
                    let rowValue = row[key].toString().toLowerCase();
                    if (rowValue.includes && rowValue.includes(this.vaccinationSearchQuery.toLowerCase())) {
                        isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.vaccinationPagination.total = result.length;
            return result.slice(this.fromVaccination, this.toVaccination);
        },
        toVaccination()
        {
            let highBound = this.fromVaccination + this.vaccinationPagination.perPage;
            if (this.totalVaccination < highBound) {
                highBound = this.totalVaccination;
            }
            return highBound;
        },
        fromVaccination()
        {
            return this.vaccinationPagination.perPage * (this.vaccinationPagination.currentPage - 1);
        },
        totalVaccination()
        {
            this.vaccinationPagination.total = this.vaccinationTableData.length;
            return this.vaccinationTableData.length;
        },

        outerVaccinatedPagedData()
        {
            return this.outerVaccinationTableData.slice(this.fromOuterVaccination, this.toOuterVaccination);
        },
        queriedOuterVaccinationData()
        {
            if (!this.outerVaccinationSearchQuery) {
                this.outerVaccinationPagination.total = this.outerVaccinationTableData.length;
                return this.outerVaccinatedPagedData;
            }
            let result = this.outerVaccinationTableData.filter((row) => {
                let isIncluded = false;
                for (let key of this.outerVaccinationPropsToSearch) {
                    let rowValue = row[key].toString().toLowerCase();
                    if (rowValue.includes && rowValue.includes(this.outerVaccinationSearchQuery.toLowerCase())) {
                        isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.outerVaccinationPagination.total = result.length;
            return result.slice(this.fromOuterVaccination, this.toOuterVaccination);
        },
        toOuterVaccination()
        {
            let highBound = this.fromOuterVaccination + this.outerVaccinationPagination.perPage;
            if (this.totalOuterVaccination < highBound) {
                highBound = this.totalOuterVaccination;
            }
            return highBound;
        },
        fromOuterVaccination()
        {
            return this.outerVaccinationPagination.perPage * (this.outerVaccinationPagination.currentPage - 1);
        },
        totalOuterVaccination()
        {
            this.outerVaccinationPagination.total = this.outerVaccinationTableData.length;
            return this.outerVaccinationTableData.length;
        },

        surveyPagedData()
        {
            return this.surveyTableData.slice(this.fromSurvey, this.toSurvey);
        },
        queriedSurveyData()
        {
            if (!this.surveySearchQuery) {
                this.surveyPagination.total = this.surveyTableData.length;
                return this.surveyPagedData;
            }
            let result = this.surveyTableData.filter((row) => {
                let isIncluded = false;
                for (let key of this.surveyPropsToSearch) {
                    let rowValue = row[key].toString().toLowerCase();
                    if (rowValue.includes && rowValue.includes(this.surveySearchQuery.toLowerCase())) {
                        isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.surveyPagination.total = result.length;
            return result.slice(this.fromSurvey, this.toSurvey);
        },
        toSurvey()
        {
            let highBound = this.fromSurvey + this.surveyPagination.perPage;
            if (this.totalsurvey < highBound) {
                highBound = this.totalSurvey;
            }
            return highBound;
        },
        fromSurvey()
        {
            return this.surveyPagination.perPage * (this.surveyPagination.currentPage - 1);
        },
        totalSurvey()
        {
            this.surveyPagination.total = this.surveyTableData.length;
            return this.surveyTableData.length;
        },


    },
    mounted()
    {
        this.init();
        // Pradeep Start
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;

        //Bus.$emit("projectype", vm.project);
        Bus.$on('tab.selected', (tab) => {
            var activeTab = tab.title;
            if (activeTab == "Vaccinations") {
                vm.getArchivedVaccinations();
            } else
                if (activeTab == "Out of Area") {
                    vm.getArchivedOuterVaccinations();
                } else
                    if (activeTab == "Surveys") {
                        vm.getArchivedSurveys();
                    }
        });
        // Pradeep End
    }

}
</script>
<style lang="scss">
.set_font_title {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-left: -12px !important;
}

.modal_full_img {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  padding: 20px;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
  display: none;
}

/* Modal Content (image) */
.modal-content-full-img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  //height: 100%;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content-full-img, #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}


@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }
  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

/* The Close Button */
.colse-one {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.colse-one:hover,
.colse-one:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content-full-img {
    width: 100%;
  }
}


.btn-group .btn + .btn {
  margin-left: 5px !important;
}
</style>
